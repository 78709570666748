<template>
  <router-view :logged-user="loggedUser" />
</template>

<script>
export default {
  name: 'MainLayout',
  props: {
    loggedUser: { type: Object, required: true },
  },
};
</script>
